@import "../../../sass/variables";

.uploadWrapperDiv {
  background-color: $gray-400;
  height: 160px;
  width: 160px;
  position: relative;
  clip-path: circle(50%);

  input {
    display: none;
  }
}

.uploadPreviewDiv {
  height: 160px;
  width: 160px;

  img {
    height: 160px;
    width: 160px;
    border-radius: 50%;
  }
}

.uploadButton {
  width: 100%;
  height: 20%;
  position: absolute;
  bottom: 0;
  background-color: $gray-400;
  color: $black-200;
  border: none;
  font-size: 15px;
}
