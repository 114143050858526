.p-calendar {
  width: 100%;

  &.p-calendar-w-btn {
    .p-inputtext {
      border-radius: 5px;
      margin-bottom: 0;
    }
  }

  .p-datepicker-trigger {
    position: absolute;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: transparent;
    border: 0;
    color: $gray-600;

    &:focus {
      box-shadow: none;
    }

    &:hover {
      background-color: $gray-300;
      color: $gray-600;
    }
  }
  &.p-datetimepicker-sm {
    width: 155px;
    .p-inputtext {
      height: 36px;
      padding: 6px 20px;
      font-size: 14px;
    }
  }
}

.p-datepicker {
  padding: 0;

  .p-datepicker-header {
    padding: 20px 0.5rem;
    border-bottom: 1px solid $gray-1100;

    .p-datepicker-title {
      text-transform: uppercase;
      font-size: 14px;
      color: #2e3a58;
    }
  }

  .p-datepicker-calendar-container {
    .p-datepicker-calendar {
      tr > td {
        &:first-child {
          padding-left: 10px;
        }

        &:last-child {
          padding-right: 10px;
        }
      }

      tr > th {
        &:first-child {
          span {
            padding-left: 10px;
          }
        }

        &:last-child {
          span {
            padding-right: 10px;
          }
        }
      }

      th {
        text-align: center;
        border-bottom: 1px solid $gray-1100;
        padding-bottom: 1rem;
        color: #8e9ab3;
        font-size: 15px;
      }

      td {
        padding: 0;

        span {
          font-weight: bold;
          font-size: 15px;
          width: 44px;
          height: 44px;
          border-radius: 5px;

          &.p-disabled {
            color: #8e9ab3;
            opacity: 1;
          }

          &.p-highlight {
            border: 1px solid $indigo-1000;
            background-color: rgba($indigo-1000, 0.08);
          }

          &:hover {
            background-color: rgba($indigo-1000, 0.08) !important;
          }
        }

        &.p-datepicker-today {
          span {
            background-color: transparent;
            position: relative;

            &::before {
              position: absolute;
              content: '';
              width: 6px;
              height: 6px;
              border-radius: 50%;
              background-color: $indigo-1000;
              bottom: 4px;
              left: 50%;
              transform: translateX(-50%);
            }

            &.p-highlight {
              border: 1px solid $indigo-1000;
              background-color: rgba($indigo-1000, 0.08);
            }
          }
        }
      }
    }
  }
}
