.activity-date {
  color: $gray-600;
}

.activity-list {
  list-style: none;
  padding-left: 0;
}

.icon-green {
  background-color: #cef2c4;
}

.icon-red {
  background-color: #f2c4c4;
}

.icon-blue {
  background-color: #c4daf2;
}

.icon-mock-img-wrapper {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-mock-img {
  font-size: 0.8rem;
  font-weight: 500;
}

.activity-time-wrapper {
  align-items: center;
}

.activity-time {
  color: $gray-600;
  min-width: 60px;
}

.activity-text-wrapper {
  align-items: center;
}

.activity-person {
  color: $indigo-800;
  font-weight: 600;
}

.activity-location {
  color: $indigo-800;
  font-weight: 600;
}

.pb10 {
  padding-left: 12px;
  padding-top: 14px;
  padding-bottom: 0 !important;
}

li.pb10 {
  margin-top: -2px;
}

.button-view-all {
  padding-top: 14px;
}
