.dashboardCount {
  margin-top: 40px;
  p {
    font-size: 16px;
    margin-top: 5px;
  }
  &:last-child {
    margin-bottom: 30px;
  }
}

@media (max-width: 1399px) {
  h1 {
    font-size: 36px;
  }
}
