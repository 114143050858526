.auth-template-bg {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $gray-100;
  padding: 0 15px;
}

.auth-template-card {
  background-color: $white;
  border-radius: 8px;
  box-shadow: 0px 3px 6px rgba($color: $black, $alpha: 0.16);
  padding: 35px 30px;
  max-width: 505px;
  width: 505px;
}

.auth-template-logo-wrapper {
  padding: 10px 0;
}

.auth-template-logo-img {
  width: 275px;
  padding-bottom: 9px;
}

.auth-template-card-header {
  padding: 15px 0;
  text-align: center;
}

.auth-template-header-text {
  font-size: 14px;
}

.footer-link-wrapper {
  margin-top: 15px;
  display: flex;
  align-items: center;
}

.footer-link-text-wrapper {
  text-decoration: none;
  margin-left: 3px;
}

.form-errors {
  padding: 5px 15px;
  background-color: $red-300;
  border-radius: 3px;
  width: 100%;
  margin-top: 10px;
}

.form-success {
  padding: 5px 15px;
  background-color: $green-300;
  border-radius: 3px;
  width: 100%;
  margin-top: 10px;
}

.form-error-message {
  font-size: 14px;
  color: #db1e1e;
}
.auth-template-helper-text-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.footer-link-text {
  color: #4a5568 !important;
}
.auth-template-helper-text {
  color: #2d3748 !important;
}
