.sidebarWrapper {
  background: #1a202c;
  height: 100vh;
  width: 270px;
  overflow-y: auto;
}

.menuSection {
  padding-top: 10px;
  padding-bottom: 10px;
}

.logoContainer {
  img {
    display: block;
    width: 215px;
    height: auto;
    margin: auto;
    padding: 30px 10px 25px;
  }
}

.seeAllLink {
  line-height: 18px;
  a {
    font-size: 14px;
    color: #63b3ed;
    text-decoration: none;
  }
}
