html,
.App,
#root {
  height: 100%;
}

.app-wrapper {
  display: flex;
}
.App {
  width: 100%;
}

body {
  font-family: $primary-font;
  height: 100%;
}

.p-component {
  font-family: $primary-font;
}
.p-card {
  border-radius: 8px;
  @include box-shadow(0, 3px, 4px, rgba(0, 0, 0, 0.16));
  margin-bottom: 20px;
  &.card-padding-20 {
    .p-card-body {
      padding: 20px;
    }
  }
  .p-card-body {
    .p-card-content {
      padding: 0;
    }
  }
  &.p-card-w-table {
    .p-card-body {
      padding: 10px 15px 0;
    }
    &--notab {
      .p-datatable {
        margin-top: 0;
        .p-datatable-header {
          padding-top: 5px;
        }
      }
    }
  }
}
.text-ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.p-tabview {
  .p-tabview-panels {
    padding: 0;
    background-color: transparent;
    .p-datatable {
      margin-bottom: 0;
    }
  }
}

.input-mb0 {
  .mb25,
  input {
    margin-bottom: 0 !important;
  }
}

.input-mb20 {
  .mb25 {
    margin-bottom: 18px !important;
  }
}

.btn-min-w-110 {
  button {
    min-width: 110px;
  }
}

@media screen and (min-width: 1399px) {
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
}
.p-badge.p-badge-info {
  background-color: $blue-1100; //color adjusted according to wave plugin
}

.no-label {
  margin-top: 5px;
  label,
  br {
    display: none;
  }
}
.no-label-datepicker {
  margin-top: 28px;
}

.tab-view-align-top {
  .p-tabview .p-tabview-nav {
    margin: -25px -30px 15px;
  }
}
// TO-DO: Have to review this styles. Customized  as follows for an urgent need
.tab-view-align-custom {
  .p-tabview .p-tabview-nav {
    margin: -14px -13px 15px;
  }
}

.mw-150px {
  min-width: 150px;
}

@media screen and (min-width: 992px) and (max-width: 1350px) {
  .p-paginator-left-content {
    width: 100%;
  }
  .p-paginator {
    justify-content: flex-start;
  }
}

@media screen and (max-width: 850px) {
  .p-paginator-left-content {
    width: 100%;
  }
  .p-paginator {
    justify-content: flex-start;
  }
}

