.p-radiobutton{
    position: relative;
    top: -2px;
    + label{
        margin-bottom: 0;
        margin-left: 15px;
    }
    .p-radiobutton-box{
        &.p-highlight {
            border-color: #B4C2FF;
            background: #4969F6;
        }
        .p-radiobutton-icon {
            width: 8px;
            height: 8px;
        }
    }
    
}