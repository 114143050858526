@import '../../../sass/variables';

.sidebarMenuItem {
  border: 0;
  background: $gray-900;
  box-shadow: none;
  border-radius: 0px;
  width: 100%;
  min-height: 40px;
  border-left: 5px solid $gray-900;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 25px;
  font-weight: bold;

  &:hover,
  &:focus {
    background: $black-100;
  }
  &.active {
    border-left: 5px solid $blue-300;
    background: $black-100;
  }
  p {
    line-height: 1;
  }
}

.sidebarMenuItemTextOnly {
  border: 0;
  background: $gray-900;
  box-shadow: none;
  border-radius: 0px;
  width: 100%;
  min-height: 40px;
  border-left: 5px solid $gray-900;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 0px;
  padding: 5px 25px;
  font-weight: bold;

  &:hover,
  &:focus {
    background: $black-100;
  }
  &.active {
    border-left: 5px solid $blue-300;
    background: $black-100;
  }
  p {
    line-height: 1;
  }
}
