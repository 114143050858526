.p-tabview {
  .p-tabview-nav {
    border: none;
    border-bottom: 1px solid $gray-1100;
    margin: 0 -15px;
    li {
      margin-left: 15px;
      .p-tabview-nav-link {
        color: $gray-600;
        border: none;
        line-height: normal;
        background: transparent;
        margin: 0;
        height: 100%;
        border-bottom: 4px solid $white;
        padding-bottom: 10px;
        .p-badge-danger {
          background-color: $red-500;
          font-size: 16px;
          padding: 0 10px;
          border-radius: 20px;
        }
        &:not(.p-disabled):focus {
          box-shadow: none;
        }
      }
      &.p-highlight {
        .p-tabview-nav-link {
          color: $gray-800;
          border-bottom: 4px solid $blue-400;
        }
      }
      &:not(.p-highlight):not(.p-disabled):hover {
        .p-tabview-nav-link {
          border-bottom: 4px solid $blue-400;
        }
      }
    }
  }
}
li.p-unselectable-text.dashboard-header-tabs.last-col-tab {
  margin-left: auto;
}
