.pi-24 {
  font-size: 24px;
}

.modal-icon {
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-position: center left;
  display: block;

  &.icon-mobile {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11.829' height='17.652' viewBox='0 0 11.829 17.652'%3E%3Cg id='Group_747' data-name='Group 747' transform='translate(-597 -235.793)'%3E%3Cpath id='Path_619' data-name='Path 619' d='M21.963,3.106a1.054,1.054,0,0,0-.761.843l-.107.64a.7.7,0,0,1-.692.587H16.191a.7.7,0,0,1-.693-.587l-.107-.64a1.054,1.054,0,0,0-.72-.831' transform='translate(584.617 233.931)' fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' stroke-width='2'/%3E%3Cpath id='Path_620' data-name='Path 620' d='M27.351,5a.351.351,0,1,0,.351.351A.351.351,0,0,0,27.351,5Z' transform='translate(576.616 232.702)'/%3E%3Cpath id='Path_621' data-name='Path 621' d='M15.914,4.053H14.861m4.563,14.392H12.4a1.4,1.4,0,0,1-1.4-1.4V4.4A1.4,1.4,0,0,1,12.4,3h7.02a1.4,1.4,0,0,1,1.4,1.4V17.041A1.4,1.4,0,0,1,19.425,18.445Z' transform='translate(587 234)' fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' stroke-width='2'/%3E%3C/g%3E%3C/svg%3E%0A");
  }

  &.icon-signal {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20.208' height='15.757' viewBox='0 0 20.208 15.757'%3E%3Cg id='Group_750' data-name='Group 750' transform='translate(-588 -811)'%3E%3Cpath id='Path_180' data-name='Path 180' d='M11.306,10.023h8.9V13.26h-8.9Zm0,6.474h8.9v3.237h-8.9ZM9.283,11.642a3.642,3.642,0,1,0-5.665,3.027v.21a.809.809,0,0,0,.809.809H6.855a.809.809,0,0,0,.809-.809v-.21A3.637,3.637,0,0,0,9.283,11.642ZM6.855,20.948l-.809.809H5.237l-.809-.809v-5.26H6.855Z' transform='translate(587 804)' fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' stroke-width='2'/%3E%3Cpath id='Path_181' data-name='Path 181' d='M6,14.023h4.046a2.023,2.023,0,0,0-4.046,0Z' transform='translate(584.618 801.619)' fill='%23fff' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' stroke-width='2'/%3E%3Cpath id='Path_182' data-name='Path 182' d='M12,32h.809M12,34.023h.809' transform='translate(581.046 789.711)' fill='none' stroke='%23000' stroke-linejoin='round' stroke-miterlimit='10' stroke-width='2'/%3E%3C/g%3E%3C/svg%3E%0A");
  }

  &.icon-credentials {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18.521' height='13.169' viewBox='0 0 18.521 13.169'%3E%3Cg id='Group_749' data-name='Group 749' transform='translate(-622 -813.294)'%3E%3Cg id='Group_748' data-name='Group 748'%3E%3Cpath id='Path_186' data-name='Path 186' d='M12,10a2,2,0,1,0,2,2A2,2,0,0,0,12,10Z' transform='translate(619.261 807.878)'/%3E%3Cpath id='Path_187' data-name='Path 187' d='M6.107,13.319a4.561,4.561,0,0,1,0-6.481M3.4,4.2a8.392,8.392,0,0,0,0,11.757m9.012-9.119a4.561,4.561,0,0,1,0,6.481m2.7,2.638a8.205,8.205,0,0,0,2.4-5.879,8.205,8.205,0,0,0-2.4-5.879' transform='translate(622 809.8)' fill='none' stroke='%23000' stroke-miterlimit='10' stroke-width='2'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
  }

  &.icon-padlock {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17.996' height='24.852' viewBox='0 0 17.996 24.852'%3E%3Cg id='Group_823' data-name='Group 823' transform='translate(-1337 -559)'%3E%3Cpath id='Path_188' data-name='Path 188' d='M26.426,14.2V10.713a5.713,5.713,0,0,0-11.426,0V14.2' transform='translate(1325.285 555)' fill='none' stroke='%23718096' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2'/%3E%3Cpath id='Path_189' data-name='Path 189' d='M19,17a8,8,0,1,0,8,8,8,8,0,0,0-8-8Z' transform='translate(1327 549.855)' fill='none' stroke='%23718096' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2'/%3E%3Cpath id='Path_190' data-name='Path 190' d='M23.714,28a1.714,1.714,0,1,0,1.714,1.714A1.714,1.714,0,0,0,23.714,28Z' transform='translate(1322.284 545.14)' fill='%23718096'/%3E%3Cpath id='Path_191' data-name='Path 191' d='M25.143,31H24v2.856a.571.571,0,0,0,.571.571h0a.571.571,0,0,0,.571-.571Z' transform='translate(1321.427 543.854)' fill='%23718096'/%3E%3C/g%3E%3C/svg%3E%0A");
  }

  &.icon-h-100 {
    background-size: auto 100%;
  }
}
