.rbc-calendar {
  .rbc-today {
    background-color: inherit;
  }
  .rbc-current-time-indicator {
    background-color: inherit;
  }
  .rbc-time-view {
    border: none;
    .rbc-time-header-content {
      border: none;
      .rbc-header {
        color: #718096;
        font-weight: normal;
        font-size: 14px;
        padding: 10px 0;
        + .rbc-header {
          border-left: none;
        }
      }
      .rbc-allday-cell {
        display: none;
      }
    }
    .rbc-time-content {
      border: none;
      border-right: 1px solid #cfdadf;
      overflow-y: visible;
      .rbc-time-column {
        &.rbc-time-gutter {
          .rbc-timeslot-group {
            border: none;
          }
        }
      }
      > * + * > * {
        border-color: #cfdadf;
      }

      .rbc-timeslot-group {
        border-color: #cfdadf;
        .rbc-time-slot {
          font-size: 14px;
          font-weight: normal;
          color: #718096;
          .rbc-label {
            position: relative;
            top: -10px;
          }
        }
      }
    }

    .rbc-day-slot .rbc-events-container {
      margin: 0;
    }
    .rbc-day-slot .rbc-event,
    .rbc-day-slot .rbc-background-event {
      border: 1px solid #f7fafc;
      border-radius: 0;
      font-size: 12px;
      background: #58c0f9;
    }
    .rbc-event-label {
      font-size: 100%;
    }
  }
}
