.p-steps {
    .p-steps-item {
        &::before {
            right: 0;
            left: auto;
            width: calc(50% - 20px);
            top: 50%;
            margin-top: -1rem;
            border-top: 1px solid $indigo-800;
            position: absolute;
            content: '';
        }

        &::after {
            right: auto;
            left: 0;
            width: calc(50% - 20px);
            top: 50%;
            margin-top: -1rem;
            border-top: 1px solid $indigo-800;
            position: absolute;
            content: '';
        }

        &:first-of-type {
            &::after {
                display: none;
            }
        }

        &:last-of-type {
            &::before {
                display: none;
            }
        }

        .p-menuitem-link {
            .p-steps-number {
                width: 40px;
                height: 40px;
                background-color: rgba($indigo-800, 0.5);
                border-color: rgba($indigo-800, 0.7);
                font-size: 14px;
                color: $white;
            }

            .p-steps-title {
                font-size: 14px;
            }
            &:not(.p-disabled):focus{
                box-shadow: none;
            }
        }

        &.p-steps-current {
            .p-menuitem-link {
                .p-steps-number {
                    background-color: rgba($indigo-800, 0.8);
                    font-weight: bold;
                }
            }
        }
    }
}