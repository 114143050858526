.p-inputtext {
  font-family: $primary-font;
  display: flex;
  width: 100%;
  border: 2px solid $gray-300;
  background: $gray-100;
  padding: 12.5px 20px;
  height: 50px;
  margin-bottom: 10px;
  border-radius: 5px;
  color: $black-200;
  &.p-invalid {
    border-color: $red-700;
  }
  &.p-disabled {
    border-color: $gray-400;
    background: $gray-300;
    color: $gray-700;
  }
  &:focus {
    border-color: $blue-500;
  }
  &.p-inputtextarea {
    height: auto;
  }
}
.p-error {
  color: $red-700;
}

label {
  margin-bottom: 5px;
  .p-error {
    color: $red-500;
  }
}

.p-input-icon-left > i,
.p-input-icon-right > i {
  margin-top: -0.75rem;
}
.p-input-icon-right > i:last-of-type {
  right: 0.75rem;
}
.p-input-icon-left > i:first-of-type {
  left: 0.75rem;
}

.p-password {
  position: relative;
  display: flex;
}
