@import '../../../sass/_variables.scss';

.ReceivedInformationModalGrayText {
  color: $gray-600;
}

.imageCircle {
  img {
    border-radius: 50%;
    margin-bottom: 20px;
  }
}
