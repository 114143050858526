$table-action-col-width: 100px;
$table-avator-col-padding-left: 58px;

.p-datatable {
  margin: 10px -15px;
  font-size: 12px;
  .p-datatable-header {
    background: $white;
    border: none;
    button {
      margin-right: 10px;
    }
    .p-datatable-search {
      .p-inplace-content,
      .p-inplace-display {
        display: flex;
        align-items: center;
        padding: 0;
        br {
          display: none;
        }
        .mb25 {
          margin-bottom: 0px !important;
        }
        .p-input-icon-left > i {
          margin-top: -0.5rem;
        }
        .p-inputtext {
          margin-bottom: 0;
        }
      }
    }
  }
  .p-datatable-thead > tr > th {
    background: $white;
    color: $gray-1400; //color adjusted according to wave plugin
    font-size: 12px;
    .p-column-title {
      // display: -webkit-box;
      // -webkit-line-clamp: 2;
      // -webkit-box-orient: vertical;
      // overflow: hidden !important;
      // white-space: normal !important;
    }
    &.p-col-w-avatar {
      padding-left: $table-avator-col-padding-left;
      width: 250px;
    }
    &.p-col-w-actions {
      text-align: right;
      width: $table-action-col-width;
    }
    &.column-w-expandbtn {
      width: 60px;
    }
  }
  .p-datatable-tbody > tr {
    > td {
      padding-top: 20px;
      padding-bottom: 20px;
      .p-avatar {
        background: $gray-1200;
        font-size: 10px;
        margin-right: 10px;
        min-width: 2rem;
      }
      &.p-col-w-actions {
        text-align: right;
        width: $table-action-col-width;
      }
      &.column-w-expandbtn {
        width: 60px;
      }
      .columncontent-w-expandtable {
        .p-datatable {
          margin: -20px -15px;
        }
      }
    }
    &:not(.p-highlight):hover {
      background: #f4faff !important;
    }
  }
}

.p-datatable-search-inactive {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 12.5px 12px;
  height: 50px;
  border-radius: 5px;
  min-width: 225px;
  color: $gray-700;
  font-weight: bold;
  .pi {
    margin-right: 10px;
    opacity: 0.5;
  }
  &:hover,
  &:focus {
    background: $white;
  }
}

.p-paginator {
  .p-paginator-pages {
    .p-paginator-page {
      font-size: 12px;
      &.p-highlight {
        background: rgba(67, 61, 245, 0.18);
        color: $black-300;
        border-radius: 5px;
      }
    }
  }
  &.p-paginator-bottom {
    background: $gray-1100;
  }
  .p-paginator-left-content {
    .p-multiselect {
      margin-right: 15px;
      border: 1px solid $gray-200;
      height: 40px;
      .p-multiselect-label {
        font-size: 14px;
        padding: 8px 15px;
        font-weight: 600;
        color: rgba(45, 55, 72, 0.7);
      }
    }
    .p-button {
      background: $white;
      color: rgba(45, 55, 72, 0.7);
      border: 1px solid $gray-200;
      height: 40px;
    }
  }
  .p-paginator-current {
    color: $gray-1300;
    font-size: 14px;
  }
  .p-dropdown {
    width: 80px;
    background: $white;
    .p-inputtext,
    .p-dropdown-trigger {
      background: $white;
    }
    .p-dropdown-label {
      height: auto;
      padding: 6px 15px;
      color: rgba(45, 55, 72, 0.7);
    }
  }
}

.data-table-overlay-menu {
  width: auto;
  min-width: 15rem;
}

.datatable-scroll-demo .loading-text {
  display: block;
  background-color: #f1f1f1;
  min-height: 19px;
  animation: pulse 1s infinite ease-in-out;
  text-indent: -99999px;
  overflow: hidden;
}

.p-datatable-scrollable-header-box {
  margin-right: 0 !important;
}

.datatable-text {
  overflow: hidden;
  text-overflow: ellipsis;
}
