.pDialogHeader {
  background-color: aqua !important;
}
.p-dialog-header {
  padding: 30px 30px 0 30px !important;
  align-items: baseline;
}

.dialogBoxSize {
  width: 60%;
  max-width: 600px;
  min-width: 300px;
  max-height: 600px;
}
