.bodyTemplate {
  font-size: 12;
  ul {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    li {
      margin-bottom: 0.3em;
    }
  }
}
