.p-togglebutton {
  margin-bottom: 5px;
  margin-right: 5px;
  &.p-button {
    border-radius: 30px;
    padding: 8px 28px;
    font-size: 14px;
    font-weight: normal;
    min-width: 100px;
    &.pill-primary {
      color: $indigo-800;
      border-color: $indigo-800;
    }
    &.pill-error {
      color: #f46464;
      border-color: #f46464;
    }
    &.pill-success {
      color: #1fa245;
      border-color: #1fa245;
    }
    &.pill-info {
      color: #3697cc;
      border-color: #3697cc;
    }
    &:not(.p-disabled):not(.p-highlight):hover,
    &:not(.p-disabled):not(.p-highlight):focus,
    &.p-highlight {
      color: #ffffff;
      &.pill-primary {
        background-color: $indigo-800;
        border-color: $indigo-800;
      }
      &.pill-error {
        background-color: #f46464;
        border-color: #f46464;
      }
      &.pill-success {
        background-color: #1fa245;
        border-color: #1fa245;
      }
      &.pill-info {
        background-color: #3697cc;
        border-color: #3697cc;
      }
    }
    &.p-pill-sm {
      font-size: 12px;
      width: 150px;
    }
  }
}
