.p-checkbox{
    position: relative;
    top: -4px;
    + label{
        margin-bottom: 0;
        margin-left: 15px;
    }
    .p-checkbox-box{
        background-color: #F7F9FC;
        border-color: #E3E9F1;
        min-width: 25px;
        width: 25px;
        height: 25px;
        border-radius: 5px;
        .p-checkbox-icon{
            font-size: 12px;
        }
        &.p-highlight{
            background: #4969F6;
            border-color: #B4C2FF;
        }
        &.p-disabled{
            background: #E2E8EF;
            border-color: #D0D8E3;
            opacity: 1;
            .p-checkbox-icon{
                color: #A0AEBF;
            }
        }
    }
    &:not(.p-checkbox-disabled){
        .p-checkbox-box:hover{
            border-color: #B4C2FF;
        }
        .p-checkbox-box.p-highlight:hover{
            background: #4969F6;
            border-color: #B4C2FF;
        }
    }
     
}