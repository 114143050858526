@import '../../../sass/_variables.scss';

.memberModalLabel {
  color: $gray-600;
}

.memberModalBlackText {
  color: $black;
}
.memberModalBlackText1 {
  color: $black;
  font-size: 1.5rem;
}

.requestButton {
  margin-left: auto;
}
