.listbox-wrapper {
  border: 1px solid #a0aec0;
  position: relative;

  &::after {
    position: absolute;
    content: '';
    bottom: 0;
    left: 0;
    right: 0;
    height: 30px;
    background-color: $gray-200;
  }

  .p-listbox {
    border: 0;

    .p-listbox-header {
      padding: 0.5rem;
      background-color: $gray-200;

      .p-listbox-filter {
        margin-bottom: 0;
        border: 1px solid #a0aec0;
      }
    }

    .p-listbox-list-wrapper {
      margin-bottom: 25px;
      .p-listbox-list {
        padding: 0;

        .p-listbox-item {
          height: 48px;
          display: flex;
          align-items: center;
          padding: 0 22px;
          margin-bottom: 2px;
          font-size: 14px;

          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .p-checkbox {
    padding: 0 22px;
    top: unset;
    z-index: 2;
    height: 30px;
    align-items: center;

    .p-checkbox-box {
      width: 16px;
      min-width: 16px;
      height: 16px;
      border-radius: 2px;
      border: 1px solid #707070;
      background-color: $white;

      .p-checkbox-icon {
        font-size: 10px;
      }

      &.p-highlight {
        background-color: $blue-1000;
        border-color: $indigo-300;
      }
    }
  }

  label {
    font-size: 14px;
    z-index: 2;
    position: relative;
    top: -3px;
  }

  &--group {
    .p-listbox {
      .p-listbox-list-wrapper {
        .p-listbox-list {
          .p-listbox-item {
            padding: 0 22px 0 72px;
          }

          .p-listbox-item-group {
            font-weight: 400;
            padding: 0.75rem 22px;
            font-size: 14px;
          }
        }
      }
    }
  }
}
