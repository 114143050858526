.p-message{
    border:1px solid $gray-200;
    border-radius: 10px;
    color: $gray-700;
    min-height: 50px;
    .p-message-wrapper {
        padding: 0.563rem 1.5rem 0.563rem 2rem;
        min-height: 50px;
    }
    &.p-message-success {
        background: $green-100;
        color: $gray-700;
        border:1px solid $gray-200;
    }
    &.p-message-error {
        background: $red-100;
        color: $gray-700;
        border:1px solid $gray-200;
    }
    &.p-message-info {
        background: rgba(88,192,249, 0.08);
        color: $gray-700;
        border:1px solid $gray-200;
    }
    &.p-message-warn {
        background: rgba(255,193,7, 0.08);
        color: $gray-700;
        border:1px solid $gray-200;
    }
}