.page-sidebar {
  position: fixed;
  left: -300px;
  transition: all 0.3s ease;
  z-index: 1;
  @media screen and (min-width: 992px) {
    left: 0 !important;
  }
  &.open {
    left: 0 !important;
  }
}
.page-wrapper {
  height: 100%;
  transition: all 0.3s ease;
  @media screen and (min-width: 992px) {
    margin-left: 270px;
  }
  .page-header {
    height: 72px;
    background: #ffffff;
    padding: 20px 30px 20px 80px;
    @media screen and (min-width: 992px) {
      padding: 20px 30px;
    }
  }
  .page-content {
    height: 100%;
  }
  .page-body {
    background: #f4f5f7;
    flex-grow: 1;
    padding: 25px 30px;
  }
  &--inner {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}
.page-mobile-sidebarbtn {
  position: absolute;
  left: 0;
  padding: 0 20px;
  height: 72px;
  background: $indigo-700;
  top: 0px;
  color: #fff;
  align-items: center;
  cursor: pointer;
}
