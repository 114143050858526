@import '../../../sass/_variables.scss';

.accountModalLabel {
  color: $gray-600;
}

.accountModalBlackText {
  color: $black;
}

.accountModalBlackText1 {
  color: $black;
  font-size: 1.5rem;
}

.imageCircle {
  img {
    border-radius: 50%;
    margin-bottom: 20px;
  }
}

.requestButton {
  margin-left: auto;
}

.gymLink {
  background: none;
  border: none;
  text-decoration: underline;
  color: $blue-600;
  padding: none;
}
