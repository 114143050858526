.p-dropdown {
  border: 2px solid $gray-300;
  background-color: $gray-100;
  width: 100%;
  border-radius: 5px;
  height: 50px;
  text-overflow: ellipsis;
  .p-inputtext {
    margin-bottom: 0;
    background-color: $gray-100;
    height: 46px;
  }
  .p-dropdown-trigger {
    background-color: $gray-100;
  }
  &.p-disabled {
    border-color: #d0d8e3;
    .p-inputtext {
      background-color: $gray-300;
    }
    .p-dropdown-trigger {
      background-color: $gray-300;
    }
  }
  &.p-dropdown-bold {
    .p-dropdown-item {
      overflow: hidden;
    }
  }
  &.p-dropdown-sm {
    .p-inputtext {
      padding: 6px 20px;
      font-size: 14px;
      align-items: center;
    }
  }
}
.p-multiselect {
  height: 50px;
  border: 2px solid $gray-300;
  border-radius: 5px;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
  .p-checkbox {
    margin-right: 1rem;
  }
  padding: 0.75rem 1rem;
}
.p-multiselect.p-multiselect-chip .p-multiselect-token {
  border-radius: 20px;
  background: $gray-1500;
  font-size: 12px;
  font-weight: 600;
  margin-top: 5px;
}
.p-multiselect .p-multiselect-label {
  padding: 12.5px 20px;
}
.p-multiselect-panel
  .p-multiselect-items
  .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
  background: #e8f3ff;
}
.p-inputwrapper-filled.p-multiselect.p-multiselect-chip .p-multiselect-label {
  height: 50px;
  background: $gray-100;
}
.p-dropdown .p-dropdown-label.p-placeholder {
  align-items: center;
  color: #4a5568;
}
