.status {
  text-align: center;
  border-radius: 1.5rem;
  min-width: 200px;
  padding: 5px 10px;
  color: #fff;
}

.textBold {
  font-weight: bold;
}

.textNormal {
  font-weight: normal;
}

.green-variant {
  background-color: #2ecc71;
}
.yellow-variant {
  background-color: #f1c40f;
}
.red-variant {
  background-color: #ec3a2d;
}
.blue-variant {
  background-color: #228fce;
}
