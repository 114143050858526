h1,
.h1 {
  font-weight: $fw-bold;
  @include font-size($h1-font-sizes);
  margin-bottom: 0;
}

h2,
.h2 {
  font-weight: $fw-bold;
  @include font-size($h2-font-sizes);
  margin-bottom: 0;
}

h3,
.h3 {
  @include font-size($h3-font-sizes);
  margin-bottom: 0;
}

h4,
.h4 {
  @include font-size($h4-font-sizes);
  margin-bottom: 0;
}

h5,
.h5 {
  @include font-size($h5-font-sizes);
  margin-bottom: 0;
}

h6,
.h6 {
  @include font-size($h6-font-sizes);
  margin-bottom: 0;
}

p,
b,
strong,
i,
em,
mark,
del,
ins,
.p {
  @include font-size($p-font-sizes);
  margin-bottom: 0;
}

$sides: (
  'b': 'bottom',
  't': 'top',
  'l': 'left',
  'r': 'right'
);
@each $prefix, $value in $sides {
  $property: if($prefix == '', '', -#{$value});
  @for $i from 0 through 100 {
    .m#{$prefix} {
      &#{($i)} {
        margin#{$property}: #{($i)}px !important;
      }
    }
    .p#{$prefix} {
      &#{($i)} {
        padding#{$property}: #{($i)}px !important;
      }
    }
  }
}

@mixin box-shadow($horiz, $vert, $blur, $color) {
  -webkit-box-shadow: $horiz $vert $blur $color;
  -moz-box-shadow: $horiz $vert $blur $color;
  box-shadow: $horiz $vert $blur $color;
}
