.sidebarMenuItem-icon {
  width: 22px;
  height: 22px;
  margin-right: 10px;
  img {
    vertical-align: top;
  }
  .p-avatar {
    width: 22px;
    height: 22px;
    background: $gray-1200;
  }
}

.sidebarMenuItem-name {
  width: 60%;
  text-align: left;
}

.sidebarMenuItem-textOnly {
  text-align: left;
}

.sidebarMenuItem-padding {
  padding-right: 0px;
}

.sidebarMenuItem-text {
  overflow: hidden;
  text-overflow: ellipsis;
}

.sidebarMenuItem-count {
  width: 32px;
  margin-left: 10px;
}

.sidebarProfile {
  background: $black-100;
  padding: 20px;
  &--image {
    display: flex;
    align-items: center;
    .p-avatar {
      width: 42px;
      height: 42px;
    }
  }
  &--detail {
    flex-grow: 1;
    padding-left: 15px;
    max-width: 140px;
    h5 {
      font-weight: bold;
      color: $white !important;
      line-height: 1.5;
    }
    p {
      font-weight: bold;
      color: $gray-1000 !important;
    }
  }
  &--menu {
    .p-button.p-button-text:enabled {
      &:focus,
      &:hover {
        background: transparent;
      }
      .pi {
        font-size: 24px;
        color: $gray-1000;
      }
    }
  }
}
