.p-dialog-top {
  .p-dialog {
    margin: 0.75em !important; //overriding prime-react modal styles
  }
}

.p-dialog {
  .p-dialog-header {
    padding: 30px;
    .p-dialog-title {
      font-size: 26px;
    }
  }
  .p-dialog-content {
    padding: 0 30px 30px 30px;
  }
}

.detailed-modal {
  .p-dialog-header {
    cursor: default;
    height: 95vh;

    .p-dialog-title {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;

      .p-tabview {
        width: 100%;
        height: 100%;

        .p-tabview-nav {
          .p-tabview-nav-link {
            padding-top: 0;
          }
        }

        .p-tabview-panels {
          max-height: calc(100% - 31px);
          overflow-y: auto;
          overflow-x: hidden;

          .img-circle {
            border-radius: 50%;
            margin-bottom: 20px;
          }

          .p-tabview-panel {
            height: 100%;
          }
        }
      }
    }
  }

  .p-dialog-header-icons {
    position: absolute;
    top: 20px;
    right: 25px;
  }

  .p-dialog-content {
    display: none;
  }

  &__content-col {
    overflow-y: auto;
    overflow-x: hidden;
  }
}
