@font-face {
    font-family: "Heebo";
    src: url("../assets/fonts/heebo/heebo-bold-webfont.eot");
    src: url("../assets/fonts/heebo/heebo-bold-webfont.eot?#iefix") format("embedded-opentype"),
        url("../assets/fonts/heebo/heebo-bold-webfont.woff2") format("woff2"),
        url("../assets/fonts/heebo/heebo-bold-webfont.woff") format("woff"),
        url("../assets/fonts/heebo/heebo-bold-webfont.ttf") format("truetype"),
        url("../assets/fonts/heebo/heebo-bold-webfont.svg#heebobold") format("svg");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "Heebo";
    src: url("../assets/fonts/heebo/heebo-regular-webfont.eot");
    src: url("../assets/fonts/heebo/heebo-regular-webfont.eot?#iefix") format("embedded-opentype"),
        url("../assets/fonts/heebo/heebo-regular-webfont.woff2") format("woff2"),
        url("../assets/fonts/heebo/heebo-regular-webfont.woff") format("woff"),
        url("../assets/fonts/heebo/heebo-regular-webfont.ttf") format("truetype"),
        url("../assets/fonts/heebo/heebo-regular-webfont.svg#heeboregular") format("svg");
    font-weight: normal;
    font-style: normal;
}
