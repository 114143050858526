.p-button {
  background: $blue-1000;
  border-color: $blue-1000;
  transition: all 0.3s ease;
  font-weight: 600;
  padding: 0.532rem 1rem;
  font-size: 14px;
  border-radius: 5px;
  &:enabled:hover {
    background: $indigo-900;
    border-color: $indigo-900;
  }
  &:focus {
    -webkit-box-shadow: 0 0 0 0.2rem $indigo-300;
    box-shadow: 0 0 0 0.2rem $indigo-300;
  }
  &.p-button-sm {
    font-size: 14px;
    padding: 0.344rem 0.875rem;
    .pi {
      font-size: 12px;
      font-weight: bold;
    }
  }
  &.p-button-lg {
    font-size: 14px;
    height: 50px;
  }
  &.p-button-outlined {
    color: $indigo-800;
    border: 1px solid $indigo-800;
    &:enabled:hover {
      color: $indigo-800;
      background-color: $indigo-300;
    }
    &.p-disabled {
      color: $indigo-300;
      background-color: transparent;
      border-color: $indigo-300;
    }
  }
  &.p-button-text {
    color: $gray-700;
    border-style: none;
    &:enabled:hover {
      background-color: $gray-300;
      color: #707070;
    }
    &.p-disabled {
      color: $gray-500;
      background-color: transparent;
      border-style: none;
    }
  }
  &.p-disabled {
    background-color: $purple-500;
    border-color: $purple-500;
    opacity: 1;
  }

  &.p-button-icon-only {
    width: 50px;
    padding: 0;
  }
}

.p-button-text-only-slim {
  color: $indigo-800;
  transition: all 0.3s ease;
  font-weight: 600;
  font-size: 14px;
  background-color: transparent;
  padding: 0 0.5rem;
  border: 0;
  &:enabled:hover {
    background-color: transparent;
    color: $indigo-900;
  }
  &.p-disabled {
    color: $indigo-300;
    background-color: transparent;
  }
}

.p-button-success {
  background: $green-500 !important;
  border-color: $green-500 !important;
  color: $white !important;
  transition: all 0.3s ease;
  font-weight: 600;
  padding: 0.532rem 1rem;
  font-size: 14px;
  border-radius: 5px;
  &:enabled:hover {
    background: $green-600 !important;
    border-color: $green-600 !important;
  }
  &.p-button-sm {
    font-size: 14px;
    padding: 0.344rem 0.875rem;
    .pi {
      font-size: 12px;
      font-weight: bold;
    }
  }
  &.p-button-lg {
    font-size: 14px;
    height: 50px;
  }
  &:focus {
    -webkit-box-shadow: 0 0 0 0.2rem $green-300 !important;
    box-shadow: 0 0 0 0.2rem $green-300 !important;
  }
  &.p-disabled {
    background-color: $green-400 !important;
    border-color: $green-400 !important;
    opacity: 1;
  }
}

.p-button-warning {
  background: $orange-500 !important;
  border-color: $orange-500 !important;
  color: $white !important;
  transition: all 0.3s ease;
  font-weight: 600;
  padding: 0.532rem 1rem;
  font-size: 14px;
  border-radius: 5px;
  &:enabled:hover {
    background: $orange-600 !important;
    border-color: $orange-600 !important;
  }
  &.p-button-sm {
    font-size: 14px;
    padding: 0.344rem 0.875rem;
    .pi {
      font-size: 12px;
      font-weight: bold;
    }
  }
  &.p-button-lg {
    font-size: 14px;
    height: 50px;
  }
  &:focus {
    -webkit-box-shadow: 0 0 0 0.2rem $orange-300 !important;
    box-shadow: 0 0 0 0.2rem $orange-300 !important;
  }
  &.p-disabled {
    background-color: $orange-400 !important;
    border-color: $orange-400 !important;
    opacity: 1;
  }
}

.p-button-danger {
  background: $red-600 !important;
  border-color: $red-600 !important;
  transition: all 0.3s ease;
  font-weight: 600;
  padding: 0.532rem 1rem;
  font-size: 14px;
  border-radius: 5px;
  &:enabled:hover {
    background: $red-700 !important;
    border-color: $red-700 !important;
  }
  &.p-button-sm {
    font-size: 14px;
    padding: 0.344rem 0.875rem;
    .pi {
      font-size: 12px;
      font-weight: bold;
    }
  }
  &.p-button-lg {
    font-size: 14px;
    height: 50px;
  }
  &:focus {
    -webkit-box-shadow: 0 0 0 0.2rem $red-300 !important;
    box-shadow: 0 0 0 0.2rem $red-300 !important;
  }
  &.p-disabled {
    background-color: $red-400 !important;
    border-color: $red-400 !important;
    opacity: 1;
  }
}

.text-only-btn {
  font-weight: 400;
  color: $indigo-800;
  cursor: pointer;
  background-color: transparent;
  border: none;
  padding: 0;
  font-size: 14px;
  margin: 0;
  margin-bottom: 0;
  margin-left: 3px;
}
