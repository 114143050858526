// Fonts
$primary-font: "Heebo", sans-serif;

$fw-bold: bold;
$fw-normal: normal;

// Colours

$black: #000000;
$black-100: #0c1220;
$black-200: #131313;
$black-300: #161616;

$white: #ffffff;

// Gray
$gray-100: #f7f9fc;
$gray-200: #e2e8f0;
$gray-300: #e2e8ef;
$gray-400: #cad4df;
$gray-500: #a0aebf;
$gray-600: #718096;
$gray-700: #4a5568;
$gray-800: #2d3748;
$gray-900: #1a202c;
$gray-1000: #9b9b9b;
$gray-1100: #edf2f7;
$gray-1200: #e0f4fe;
$gray-1300: #7a8794;
$gray-1400: #5a616d; //newly added
$gray-1500: #cbd5e0; //newly added

// red
$red-100: #fff5f5;
$red-200: #fed7d7;
$red-300: #feb2b2;
$red-400: #fc8181;
$red-500: #f46464;
$red-600: #e53e3e;
$red-700: #c42f2f;
$red-800: #9b2c2c;
$red-900: #742a2a;

// Orange
$orange-100: #fffaf0;
$orange-200: #feebc8;
$orange-300: #fbd38d;
$orange-400: #f6ad55;
$orange-500: #ed8936;
$orange-600: #dd6b20;
$orange-700: #c05621;
$orange-800: #9c4221;
$orange-900: #7b341e;

// Yellow
$yellow-100: #fffff0;
$yellow-200: #fefcbf;
$yellow-300: #faf089;
$yellow-400: #f6e05e;
$yellow-500: #ecc94b;
$yellow-600: #d69e2e;
$yellow-700: #b7791f;
$yellow-800: #975a16;
$yellow-900: #744210;

// Green
$green-100: #f0fff4;
$green-200: #c6f6d5;
$green-300: #9ae6b4;
$green-400: #68d391;
$green-500: #48bb78;
$green-600: #38a169;
$green-700: #2f855a;
$green-800: #276749;
$green-900: #22543d;

// Teal
$teal-100: #e6fffa;
$teal-200: #b2f5ea;
$teal-300: #81e6d9;
$teal-400: #4fd1c5;
$teal-500: #38b2ac;
$teal-600: #319795;
$teal-700: #2c7a7b;
$teal-800: #285e61;
$teal-900: #234e52;

// Blue
$blue-100: #ebf8ff;
$blue-200: #bee3f8;
$blue-300: #90cdf4;
$blue-400: #58c0f9;
$blue-500: #4299e1;
$blue-600: #3182ce;
$blue-700: #2b6cb0;
$blue-800: #2c5282;
$blue-900: #2a4365;
$blue-1000: #4969f6;
$blue-1100: #0a7cba; //newly added

// Indigo
$indigo-100: #ebf4ff;
$indigo-200: #c3dafe;
$indigo-300: #b4c2ff;
$indigo-400: #7f9cf5;
$indigo-500: #667eea;
$indigo-600: #5a67d8;
$indigo-700: #4c51bf;
$indigo-800: #433df5;
$indigo-900: #050091;
$indigo-1000: #3366FF;

// Purple
$purple-100: #faf5ff;
$purple-200: #e9d8fd;
$purple-300: #d6bcfa;
$purple-400: #b794f4;
$purple-500: #9794f8;
$purple-600: #805ad5;
$purple-700: #6b46c1;
$purple-800: #553c9a;
$purple-900: #44337a;


// Pink
$pink-100: #fff5f7;
$pink-200: #fed7e2;
$pink-300: #fbb6ce;
$pink-400: #f687b3;
$pink-500: #ed64a6;
$pink-600: #d53f8c;
$pink-700: #b83280;
$pink-800: #97266d;
$pink-900: #702459;
